import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout pageId="four-oh-four">
    <div className="container">
      <div className="container">
        <h1>NOT FOUND</h1>
        <p>This page doesn't exist.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
